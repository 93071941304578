import { FilterActionsOptions } from '@supy/common';

import {
  CkCatalogItemCreatePayload,
  CkCatalogItemsRequestMetadata,
  CkCatalogItemUpdatePayload,
  CkDeleteManyRequest,
} from '../../core';
import { CkCatalogItemsFilters } from '../state';

export class CkCatalogItemsGet {
  static readonly type = '[CkCatalogItems] Get';
  constructor(readonly payload: { readonly id: string; readonly fromCache?: boolean }) {}
}

export class CkCatalogItemsGetMany {
  static readonly type = '[CkCatalogItems] GetMany';

  constructor(readonly options?: { readonly matched?: boolean; readonly deliveryDate?: Date }) {}
}

export class CkCatalogPackagingsGetMany {
  static readonly type = '[CkCatalogPackagings] GetMany';
}

export class CkCatalogItemsInitFilters {
  static readonly type = '[CkCatalogItems] InitFilters';

  constructor(readonly options?: FilterActionsOptions) {}
}

export class CkCatalogItemsPatchFilters {
  static readonly type = '[CkCatalogItems] PatchFilters';
  constructor(
    readonly payload?: Partial<CkCatalogItemsFilters>,
    readonly options?: FilterActionsOptions,
  ) {}
}

export class CkCatalogItemsResetFilters {
  static readonly type = '[CkCatalogItems] ResetFilters';

  constructor(readonly options?: FilterActionsOptions) {}
}

export class CkCatalogItemsPatchRequestMetadata {
  static readonly type = '[CkCatalogItems] PatchRequestMetadata';
  constructor(readonly payload?: Partial<CkCatalogItemsRequestMetadata>) {}
}

export class CkCatalogItemsDeleteMany {
  static readonly type = '[CkCatalogItems] DeleteMany';
  constructor(readonly payload: CkDeleteManyRequest) {}
}

export class CkCatalogItemsExport {
  static readonly type = '[CkCatalogItems] Export';
}

export class CkCatalogItemCreate {
  static readonly type = '[CkCatalogItems] Create';
  constructor(readonly payload: CkCatalogItemCreatePayload) {}
}

export class CkCatalogItemUpdate {
  static readonly type = '[CkCatalogItems] Update';
  constructor(
    readonly id: string,
    readonly payload: CkCatalogItemUpdatePayload,
  ) {}
}
