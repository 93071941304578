import { NonFunctionProperties } from '@supy/core';

import { CkCatalogItemMargin, CkCatalogItemPricingMethod } from '../catalog';
import {
  CkPriceListItemImportRequest,
  CkPriceListItemRequest,
  CkPriceListItemsImportRequest,
  CkPriceListItemsRequest,
} from './ck-price-list.model';

export class CkPriceListItemPayload {
  constructor(args: NonFunctionProperties<CkPriceListItemPayload>) {
    this.id = args.id;
    this.price = args.price;
    this.addMargin = args.addMargin;
    this.pricingMethod = args.pricingMethod;
    this.discount = args.discount;
  }

  readonly id: string;
  readonly price: number;
  readonly addMargin: CkCatalogItemMargin;
  readonly pricingMethod: CkCatalogItemPricingMethod;
  readonly discount?: number;

  serialize(): CkPriceListItemRequest {
    return {
      id: this.id,
      price: this.price,
      addMargin: this.addMargin,
      pricingMethod: this.pricingMethod,
      discount: this.discount,
    };
  }
}

export class CkPriceListItemsPayload {
  constructor(args: NonFunctionProperties<CkPriceListItemsPayload>) {
    this.items = args.items;
  }

  items: CkPriceListItemPayload[];

  serialize(): CkPriceListItemsRequest {
    return {
      items: this.items.map(item => item.serialize()),
    };
  }
}

export class CkPriceListItemImportPayload extends CkPriceListItemPayload {
  constructor(args: NonFunctionProperties<CkPriceListItemImportPayload>) {
    super(args);

    this.packagingId = args.packagingId;
  }

  readonly packagingId: string;

  serialize(): CkPriceListItemImportRequest {
    return {
      ...super.serialize(),
      packagingId: this.packagingId,
    };
  }
}

export class CkPriceListItemsImportPayload {
  constructor(args: NonFunctionProperties<CkPriceListItemsImportPayload>) {
    this.items = args.items;
    this.locationId = args.locationId;
    this.priceListId = args.priceListId;
  }

  readonly items: CkPriceListItemImportPayload[];
  readonly locationId: string;
  readonly priceListId: string;

  serialize(): CkPriceListItemsImportRequest {
    return {
      items: this.items.map(item => item.serialize()),
      locationId: this.locationId,
      priceListId: this.priceListId,
    };
  }
}
